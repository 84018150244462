<template>
    <div>
        <div class="flex" style="gap: 24px; padding: 16px; border-radius: 24px; background: white;">
            <multiselect
                class="mt-1"
                style="max-width: 207px;"
                placeholder="Customs ID"
                :options="[]"
                disabled
                track-by="id"
                label="name"
            />

            <multiselect
                class="mt-1"
                style="max-width: 207px;"
                placeholder="Date"
                :options="[]"
                disabled
                track-by="id"
                label="name"
            />

            <multiselect
                class="mt-1"
                style="max-width: 207px;"
                placeholder="Waybill"
                :options="[]"
                disabled
                track-by="id"
                label="name"
            />
        </div>
        <div class="shipments-list table-fixed border-radius">

            <div v-for="item in list" :key="item.title" class="shipments-list__item" @dblclick="goTo(item.id)">
                <div class="shipments-list-item customs-list-item border-radius--l">
                    <div class="customs-list-item__col">
                        <span class="f-w-700 f-size-16">
                            {{ item.shipment_no }}
                        </span>
                        <span :style="`color: ${statusColorMap.get(item.status)}`" class="f-w-medium">
                            {{ item.status }}
                        </span>
                    </div>
                    <div class="customs-list-item__col">
                        <span class="customs-list-item__title">
                            Registration date:
                        </span>
                        <span class="f-w-700">
                            {{ item.register_date }}
                        </span>
                    </div>
                    <div class="customs-list-item__col">
                        <span class="customs-list-item__title">
                            Waybill number:
                        </span>
                        <div class="f-w-700" style="width: 125px;">
                            {{ item.waybill_no }}
                        </div>
                    </div>
                    <div class="customs-list-item__col">
                        <span class="customs-list-item__title">
                            Importer
                        </span>
                        <span class="f-w-700">
                            {{ item.importer }}
                        </span>
                    </div>
                    <div class="customs-list-item__col">
                        <span class="customs-list-item__title">
                            HS code:
                        </span>
                        <span v-if="item.hs_codes.length <= 1" class="f-w-700">
                            {{ `${item.hs_codes.length ? item.hs_codes : '-'}` }}
                        </span>
                        <Hint v-else>
                            <template #trigger>
                                <span class="f-w-700" style="text-decoration: underline; text-decoration-style: dashed; color: #2E457C;">
                                    {{ item.hs_codes.length }} codes
                                </span>
                            </template>
                            <template #content>
                                <div>
                                    <div v-for="code in item.hs_codes" :key="code">
                                        {{ code }}
                                    </div>
                                </div>
                            </template>
                        </Hint>
                    </div>
                    <div class="customs-list-item__col">
                        <span class="customs-list-item__title">
                            Accompanying shipments:
                        </span>
                        <span class="f-w-700">
                            {{ item.accompanying_shipments }}
                        </span>
                    </div>
                    <div class="customs-list-item__delimeter" />
                    <div class="customs-list-item__col flex-grow-1 flex flex__column">
                        <a
                            :href="item.documents[0].path"
                            target="_blank"
                            v-if="item.documents.length >= 1"
                            class="flex items-center"
                            :title="item.documents[0].name"
                        >
                            <svg-importer icon-name="icons/document" class="mr-2" width="20px" />
                            {{ getFileNameShort(item.documents[0].name) }}
                        </a>
                        <a :href="item.documents[1].path" target="_blank" v-if="item.documents.length >= 2" class="flex items-center" :title="item.documents[1].name">
                            <svg-importer icon-name="icons/document" class="mr-2" width="20px" />
                            {{ getFileNameShort(item.documents[1].name) }}
                        </a>
                    </div>
                    <div class="customs-list-item__col flex-grow-1 flex flex__column">
                        <a :href="item.documents[2].path" target="_blank" v-if="item.documents.length >= 3" class="flex items-center" :title="item.documents[2].name">
                            <svg-importer icon-name="icons/document" class="mr-2" width="20px" />
                            {{ getFileNameShort(item.documents[2].name) }}
                        </a>
                        <a :href="item.documents[3].path" target="_blank" v-if="item.documents.length >= 4" class="flex items-center" :title="item.documents[3].name">
                            <svg-importer icon-name="icons/document" class="mr-2" width="20px" />
                            {{ getFileNameShort(item.documents[3].name) }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Hint from '@/components/customs/hint.vue';

export default {
    components: {
        Hint,
    },
    data() {
        return {
            statusColorMap: new Map([
                ['Done', '#25AA10'],
                ['Review', '#E39E0A'],
                ['Rejected', '#EB5757']
            ]),
        };
    },
    computed: {
        list() {
            return this.$store.getters['customs/list'];
        },
    },
    methods: {
        goTo(id) {
            this.$router.push(`/customs/${id}`);
        },
        getFileNameShort(name) {
            return name.length > 10 ? name.substring(0, 11) + '...' : name;
        },
    },
};
</script>
<style lang="scss">
@import 'src/styles/pages/shipments-list.scss';
@import 'src/styles/pages/shipments-list-items.scss';

.shipments-list {
    height: calc(100vh - 60px);
}

.shipments-list-item {
    min-height: unset;
}

.customs-list-item {
    gap: 32px;
    padding-left: 16px;

    &__title {
        color: #6A7884;
    }

    &__col {
        display: flex;
        flex-direction: column;

        svg {
            path {
                fill: #2E457C;
            }
        }
    }

    &__delimeter {
        width: 2px;
        height: 50px;
        background: #F7F7F7;
    }
}
</style>