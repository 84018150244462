<template>
    <div class="customs-hint" @mouseleave="isShow = false">
        <div ref="trigger" class="customs-hint__trigger" @mouseenter="isShow = true">
            <slot name="trigger" />
        </div>
        <transition name="fade">
            <div v-show="isShow" class="customs-hint__content">
                <slot name="content" />
            </div>
        </transition>
    </div>
</template>
<script>

export default {
    data() {
        return {
            isShow: false,
        };
    },
};
</script>
<style lang="scss">
.customs-hint {
    position: relative;

    &__content {
        min-width: 100px;
        position: absolute;
        padding: 8px 12px;
        background: #2E457C;
        color: white;
        bottom: -4px;
        left: 50%;
        transform: translate3d(-50%, 100%, 0);
        z-index: 1;
        border-radius: 8px;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid #2E457C;
            position: absolute;
            top: -8px;
            left: calc(50% - 6px);
        }

        &:after {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            background: transparent;
            display: block;
            width: 100%;
            height: 15px;
        }
    }
}
</style>